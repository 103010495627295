.position-center{
    min-height: 100vh;
    width: 100%;
}

.business-card{
    .col{
        flex: 0 0 auto;
        width: 375px;
    }

    .business-card-box{
        overflow: hidden;
        border-radius: 40px;
        border: 2px solid #EBF2FF;
        background: #FFF;
        box-shadow: 0px 8px 36px -4px rgba(0, 89, 155, 0.22);
        // height: 420px;
        min-height: 420px;

        .business-card-row {
            align-items: start;

            .col {
                width: 100%;
            }

            .business-card-row-img{
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                .business-card-img-emp{
                    width: 110px;
                    height: 110px;
                    flex-shrink: 0;
                    object-fit: cover;
                }
    
                .business-card-img-logo{
                    width: 26px;
                    height: 35px;
                    flex-shrink: 0;
                }
            }

            .business-card-name{
                color: #000;
                // font-size: 30px;
                font-size: 28px;
                font-family: Inter;
                font-weight: 700;
            }
            
            .business-card-department{
                color: #757575;
                font-size: 16px;
                font-family: Inter;
            }

            .business-card-contact{
                color: #000;
                font-size: 16px;
                font-family: Inter;
                font-weight: 600;
            }

            .business-card-icon{
                color: #00599B;
            }

            .business-card-box-qr{
                margin: 0;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);

                .business-card-qr-border{
                    padding-top: 25px;
                    padding-bottom: 25px;
                    border-radius: 16px;
                    border: 2px solid rgba(0, 89, 155, 0.20);
                }
            }

            .business-card-scan-me{
                color: #1E1E1E;
                font-size: 16px;
                font-family: Inter;
                font-weight: 600;
            }

        }
    }

    .business-card-switch{
        color: #00599B;
        text-align: center;
        font-size: 14px;
        font-family: Inter;
        text-decoration-line: underline;
    }

    .business-card-switch{
        color: #00599B;
        font-size: 14px;
        font-family: Inter;
        text-decoration-line: underline;
        cursor: pointer;
    }

    .business-card-button{
        color: #FFF;
        gap: 10px;
        padding: 15px 23px;
        border-radius: 12px;
        background: #00599B;
        box-shadow: 0px 6px 30px 8px rgba(0, 89, 155, 0.15);
    }
}